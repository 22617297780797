<template>
<div class="groups-team text-center m-2 py-2 px-4" @click="GoTo()">
      <div>
            <b-img :src="flag" />
      </div>
      <div>
            {{team.dispname}}
      </div>
</div>
</template>

<script>
export default {
      name: "GroupTeam",
      props: ["id"],
      components: {},
      data() {
            return {};
      },
      computed: {
            team() {
                  return this.$functions.getTeam(this.id);
            },
            flag() {
                  return this.$images.teamFlag(this.team.flag);
            }
      },
      methods: {
            GoTo() {
                  var url = this.$url.teamurl(this.team);
                  this.$router.push(url);                  
            }
      }
};
</script>
